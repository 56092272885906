import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSolarPanel } from '@fortawesome/free-solid-svg-icons';

function Sidebar(activeItem) {
  const [selectedFeature, setSelectedFeature] = useState({});
  const [userRole] = useState(localStorage.getItem('userRole'));
  const isLoggedIn = window.localStorage.getItem("isLoggedIn");

  useEffect(() => {

    async function fetchData() {
        let dataParametreApp;
        try {
            dataParametreApp = await axios.get(
              process.env.REACT_APP_BACKEND_IP
                + ':' + process.env.REACT_APP_BACKEND_PORT
                + '/parametreApp/getDataParamsApp'
              )
        } catch (error) {
            console.log(error)
        }

        if(dataParametreApp.data != null){
            setSelectedFeature(dataParametreApp.data)
        };
       
    }
    fetchData();
  },[])



  return (
    <>
 {isLoggedIn && (
          <>
      <div className="sticky">
        {/* <div className="app-sidebar__overlay" data-bs-toggle="sidebar" /> */}
        <div className="app-sidebar">
          <div className="side-header">
            <div className="header-brand1" >
              <img
                src={process.env.REACT_APP_BASE_URL + "/assets/images/enerco/enercoicon.png"}
                className="header-brand-img light-logo"
                alt="logo"
              />
              <img
                src={process.env.REACT_APP_BASE_URL + "/assets/images/enerco/enercoavatar.png"}
                className="header-brand-img light-logo1"
                alt="logo"
              />
              <img
                src={process.env.REACT_APP_BASE_URL + "/assets/images/enerco/enercoavatarlight.png"}
                className="header-brand-img desktop-logo"
                alt="logo" />
              <img
                src={process.env.REACT_APP_BASE_URL + "/assets/images/enerco/enercoiconlight.png"}
                className="header-brand-img toggle-logo"
                alt="logo" />

            </div>

          </div>
          <div className="main-sidemenu">

            <ul className="side-menu">
              <li>
                <h3>Menu</h3>
              </li>
              <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "suiviTpsReel" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/"
                >

                  <i className="fa fa-area-chart"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px" }} />
                  <span className="side-menu__label">Suivi Temps Réel</span>

                </a>
              </li>
           
              {
                (Object.keys(selectedFeature).length > 0 && selectedFeature.prodPvRealTime === true)
                ?
                  <li className="slide">
                    <a
                      className={"side-menu__item has-link" + (activeItem.activeItem === "prodPvRealTime" ? " active" : "")}
                      data-bs-toggle="slide"
                      href="/prodPvRealTime"
                    >
                      <div style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px"}} >
                            <FontAwesomeIcon icon={faSolarPanel} />
                          </div>
                      <span className="side-menu__label">Production PV.MT T.Réel</span>
                    </a>

                  </li>
                :
                  <></>
              }
              {
                (Object.keys(selectedFeature).length > 0 && selectedFeature.prodPvBtRealTime === true)
                ?
                  <li className="slide">
                    <a
                      className={"side-menu__item has-link" + (activeItem.activeItem === "prodPvBtRealTime" ? " active" : "")}
                      data-bs-toggle="slide"
                      href="/prodPvBtRealTime"
                    >
                      <div style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px"}} >
                            <FontAwesomeIcon icon={faSolarPanel} />
                          </div>
                      <span className="side-menu__label">Production PV.BT T.Réel</span>
                    </a>

                  </li>
                :
                  <></>
              }
              <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "historique" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/historique"
                >

                  <i className="fa fa-list-alt"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px" }} />
                  <span className="side-menu__label">Historique</span>

                </a>
              </li>
              <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "prediction" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/prediction"
                >

                  <i className="fa fa-list-alt"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px" }} />
                  <span className="side-menu__label">Prediction</span>

                </a>
              </li>


              <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "bilanEnergy" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/bilan_energie"
                >
                  <i className="fa fa-bar-chart"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px" }} />
                  <span className="side-menu__label">Bilan d'énergie</span>
                </a>

              </li>
              <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "emissionCo2" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/emissionCo2"
                >
                  <i className="fa fa-cloud"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px"}} />
                  <span className="side-menu__label">Emission CO₂</span>
                </a>

              </li>
              <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "facture" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/facture"
                >
                  <i className="fa fa-credit-card"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px" }} />
                  <span className="side-menu__label">Estimation facture STEG.MT</span>
                </a>

              </li>
              {
                (Object.keys(selectedFeature).length > 0 && selectedFeature.factureBasseTension === true)
                ?
                  <li className="slide">
                    <a
                      className={"side-menu__item has-link" + (activeItem.activeItem === "factureBasseTension" ? " active" : "")}
                      data-bs-toggle="slide"
                      href="/factureBasseTension"
                    >
                      <i className="fa fa-credit-card"
                        style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px"}} />
                      <span className="side-menu__label">Estimation Facture PV.BT</span>
                    </a>

                  </li>
                :
                  <></>
              }
              {
                (Object.keys(selectedFeature).length > 0 && selectedFeature.factureexcedentPVMT === true)
                ?
                  <li className="slide">
                    <a
                      className={"side-menu__item has-link" + (activeItem.activeItem === "factureexcedentPVMT" ? " active" : "")}
                      data-bs-toggle="slide"
                      href="/factureexcedentPVMT"
                    >
                      <i className="fa fa-calculator"
                        style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px"}} />
                      <span className="side-menu__label">Estimation Facture EX-PV.MT</span>
                    </a>

                  </li>
                :
                  <></>
              }
                 {
                (Object.keys(selectedFeature).length > 0 && selectedFeature.facturProductiblePVMT === true)
                ?
                  <li className="slide">
                    <a
                      className={"side-menu__item has-link" + (activeItem.activeItem === "facturProductiblePVMT" ? " active" : "")}
                      data-bs-toggle="slide"
                      href="/facturProductiblePVMT"
                    >
                      <i className="fa fa-calculator"
                        style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px"}} />
                      <span className="side-menu__label">Estimation Production PV.MT</span>
                    </a>

                  </li>
                :
                  <></>
              }
               
              <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "rapports" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/rapports"
                >
                  <i className="fa fa-file-text"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px" }} />
                  <span className="side-menu__label">Rapports & Analyse</span>
                </a>

              </li>

              <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "archives" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/archives"
                >
                  <i className="fa fa-archive"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px" }} />
                  <span className="side-menu__label">Archives</span>
                </a>

              </li>
              {
                (Object.keys(selectedFeature).length > 0 && selectedFeature.pression === true)
                ?
                  <li className="slide">
                    <a
                      className={"side-menu__item has-link" + (activeItem.activeItem === "pression" ? " active" : "")}
                      data-bs-toggle="slide"
                      href="/pression"
                    >
                      <i className="fa fa-tachometer"
                        style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px"}} />
                      <span className="side-menu__label">Pression</span>
                    </a>

                  </li>
                :
                  <></>
              }
              {
                (Object.keys(selectedFeature).length > 0 && selectedFeature.energie_cumulee === true)
                ?
                  <li className="slide">
                    <a
                      className={"side-menu__item has-link" + (activeItem.activeItem === "energyCumule" ? " active" : "")}
                      data-bs-toggle="slide"
                      href="/energyCumule"
                    >
                      <i className="fa fa-line-chart"
                        style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px" }} />
                      <span className="side-menu__label">Energie & CO₂ Cumulée</span>
                    </a>

                  </li>
                :
                  <></>
              }

              <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "parcMachine" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/parc_machine"
                >
                  <i className="fa fa-industry"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px" }} />
                  <span className="side-menu__label">Parc machines </span>
                </a>

              </li>
              <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "alertes" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/alertes"
                >
                  <i className="fa fa-bell"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px" }} />
                  <span className="side-menu__label">Alertes Générales (Tension/Courant)</span>
                </a>
              </li>
              <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "alertesPression" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/alertesPression"
                >
                  <i className="fa fa-bell"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px" }} />
                  <span className="side-menu__label">Alertes Pression</span>
                </a>
              </li>
              <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "alertesCosphi" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/alertesCosphi"
                >
                  <i className="fa fa-bell"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px" }} />
                  <span className="side-menu__label">Alertes facteur de puissance (COSPHI)</span>
                </a>
              </li>
              { ((userRole != null) && (( userRole == 0 ))) ? (
                <>
              <li>
                <h3>Administration</h3>
              </li>
              <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "paramsFact" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/paramsFact"
                >
                  <i className="fa fa-cog"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px" }} />
                  <span className="side-menu__label">Paramètres Facture STEG</span>
                </a>
              </li>
              {
                (Object.keys(selectedFeature).length > 0 && selectedFeature.facturProductiblePVMT === true)
                ?
                  <li className="slide">
                    <a
                      className={"side-menu__item has-link" + (activeItem.activeItem === "paramsFacturProductiblePVMT" ? " active" : "")}
                      data-bs-toggle="slide"
                      href="/paramsFacturProductiblePVMT"
                    >
                      <i className="fa fa-calculator"
                        style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px"}} />
                      <span className="side-menu__label">Paramètres Facture PR-PV.MT</span>
                    </a>

                  </li>
                :
                  <></>
              }
               <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "gestionCategory" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/gestionCategory"
                >
                  <i className="fa fa-cogs"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px" }} />
                  <span className="side-menu__label">Gestion catégories</span>
                </a>
              </li>
              <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "gestionMachines" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/gestionMachines"
                >
                  <i className="fa fa-cogs"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px" }} />
                  <span className="side-menu__label">Gestion machines</span>
                </a>
              </li>
              <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "GestionCompresseur" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/GestionCompresseur"
                >
                  <i className="fa fa-cogs"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px" }} />
                  <span className="side-menu__label">Gestion Compresseurs</span>
                </a>
              </li>
              <li className="slide">
                <a
                  className={"side-menu__item has-link" + (activeItem.activeItem === "gestionUtilisateurs" ? " active" : "")}
                  data-bs-toggle="slide"
                  href="/gestionUtilisateurs"
                >
                  <i className="fa fa-user"
                    style={{ marginLeft: "5px", marginRight: "10px" , marginTop : "6px"}} />
                  <span className="side-menu__label">Gestion utilisateurs</span>
                </a>
              </li>
              </>
               ):(<></>)
              }       
            </ul>
          </div>
        </div>
      </div>
      </>
        )}
    </>

  );
}
export default Sidebar
