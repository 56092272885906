
// import './App.css';
import * as React from 'react';
import {BrowserRouter as Router, Routes, Route , Navigate } from 'react-router-dom';
import SuiviTempsReel from './pages/SuiviTempsReel';
import Historique from './pages/Historique';
import BilanEnergie from './pages/BilanEnergie';
import Facture from './pages/Facture';
import Rapports from './pages/Rapports';
import Alertes from './pages/Alertes';
import Archives from './pages/Archives';
import ParcMachines from './pages/ParcMachines';
import Login from './pages/Login';
import Register from './pages/Register';
import Pression from './pages/Pression';
import ParametresFacture from './pages/ParametresFacture';
import EnergieCumulee from './pages/EnergieCumulee';
import ParamsApp from './pages/ParamsApp';
import GestionMachines from './pages/GestionMachines';
import EditMachine from './pages/EditMachine';
import MachineConfig from './pages/MachineConfig';
import Faq from './pages/Faq';
import GestionUtilisateur from './pages/GestionUtilisateur';
import AddUser from './pages/AddUser';
import GestionUsine from './pages/GestionUsine';
import EmissionCo from './pages/EmissionCo';
import EstimationFactureExcedentPVMT from './pages/EstimationFactureExcedentPVMT';
import ProdPvRealTime from './pages/ProdPvRealTime';
import ParametresFactureProductible from './pages/ParametresFactureProductible';
import FactureProductibleMT from './pages/EstmationFacturePRMT';
import ProdPvBtRealTime from './pages/ProdPvBtRealTime';
import FactureBT from './pages/FactureBT';
import ResetPasswordEmail from "./pages/ResetPasswordEmail";
import CheckEmail from './pages/CheckEmail';
import Verification from './pages/Verification';
import ResetPassConfirm from './pages/ResetPassConfirm';
import VerifyEmail from './pages/VerifyEmail';
import NotFoundPage from './components/NotFound';
import ProtectedRoute from "./components/ProtectedRoute";
import GestionCompresseur from './pages/GestionCompresseur';
import EditCompresseur from './pages/EditCompresseur';
import GestionCategoriesMachines from './pages/GestionCategoriesMachines';
import EditCategory from './pages/EditCategory';
import AddCategory from './pages/AddCategory';
import AlertesPression from './pages/AlertsPression';
import AlertesCosphi from './pages/AlertesCosphi';
import Prediction from './pages/Prediction';

function App() {
  const isAuthenticated = localStorage.getItem('jwt');
  const isLoggedIn = window.localStorage.getItem("loggedIn"); // Check if logged in


  return (
    <>
  
    <Router>
      <Routes>
          <Route  path='/resetPassword' element={<ResetPasswordEmail/>}/>
          <Route path="/check-email" element={< CheckEmail/>} />
          <Route path="/resetpassConfirm/:token" element={< ResetPassConfirm/>} />
          <Route path="/verify-email" element={< VerifyEmail/>} />
          <Route path="/verification/:token" element={< Verification/>} />
        { !isLoggedIn &&(
          <>
            <Route  path='/login' element={<Login/>}/>
            <Route  path='/admin/inscription' element={<Register/>}/>
            {/* <Route  path='/' element={ <Navigate to="/login" />}/> */}
           
          </>
        )}
        <Route element={<ProtectedRoute />}>
          <Route  path='/login' element={ <Navigate to="/" />}/>
          <Route  path='/admin/inscription' element={ <Navigate to="/" />}/>
          <Route  exact path='/' element={<SuiviTempsReel />}/>
          <Route  exact path='/Dashboard' element={ <SuiviTempsReel />}/>
          <Route  exact path='/ProdPvRealTime' element={ <ProdPvRealTime />}/>
          <Route  exact path='/prodPvBtRealTime' element={ <ProdPvBtRealTime />}/>
          <Route  path='/historique' element={<Historique/>}/>
          <Route  path='/prediction' element={<Prediction/>}/>
          <Route  path='/bilan_energie' element={<BilanEnergie/>}/>
          <Route  path='/facture' element={<Facture/>}/>
          <Route  path='/factureBasseTension' element={<FactureBT/>}/>
          <Route  path='/facturProductiblePVMT' element={<FactureProductibleMT/>}/>
          <Route  path='/factureexcedentPVMT' element={<EstimationFactureExcedentPVMT/>}/>
          <Route  path='/rapports' element={<Rapports/>}/>
          <Route  path='/alertes' element={<Alertes/>}/>
          <Route  path='/alertesPression' element={<AlertesPression/>}/>
          <Route  path='/alertesCosphi' element={<AlertesCosphi/>}/>
          <Route  path='/archives' element={<Archives/>}/>
          <Route  path='/parc_machine' element={<ParcMachines/>}/>
          <Route  path='/pression' element={<Pression/>}/>
          <Route  path='/energyCumule' element={<EnergieCumulee/>}/>
          <Route  path='/paramsFact' element={<ParametresFacture/>}/>
          <Route  path='/paramsFacturProductiblePVMT' element={<ParametresFactureProductible/>}/>
          <Route  path='/gestionMachines' element={<GestionMachines/>}/>
          <Route  path='/gestionCategory' element={<GestionCategoriesMachines/>}/>
          <Route  path='/editCategory/:id' element={<EditCategory/>}/>
          <Route  path='/addCategory' element={<AddCategory/>}/>
          <Route  path='/gestionUtilisateurs' element={<GestionUtilisateur/>}/>
          <Route  path='/GestionUsine' element={<GestionUsine/>}/>
          <Route  path='/ajoutUtilisateur' element={<AddUser/>}/>
          <Route  path='/faq' element={<Faq/>}/>
          <Route  path='/emissionCo2' element={<EmissionCo/>}/>
          <Route  path='/editMachine/:id' element={<EditMachine/>}/>
          <Route  path='/MachineConfig/:id' element={<MachineConfig/>}/>
          <Route  path='/admin/params' element={<ParamsApp/>}/>
          <Route path="/GestionCompresseur" element={< GestionCompresseur/>} />
          <Route  path='/editCompresseur/:id' element={<EditCompresseur/>}/>
        </Route>
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} /> // This will catch all other routes not previously defined

      </Routes>
    </Router>
  
 
  </>
  );
}

export default App;
